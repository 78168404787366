@import 'ol/ol';

.land-register-map__map-container {
  width: 100%;
  height: 65vh;
}

.land-register-map__map-spinner {
  display: block;
  margin: auto;
  margin-top: 279px;
}
