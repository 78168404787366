@mixin preloadImage($url) {
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url($url);
  }
}
