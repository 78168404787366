@import 'ros-frontend/ros/components/input/input';

.govuk-input--stripe {
  padding-top: 7px;

  @include mq($from: tablet) {
    padding-top: 5px;
  }

  &.govuk-input--error {
    padding-top: 5px;

    @include mq($from: tablet) {
      padding-top: 3px;
    }
  }

  &.govuk-input--focus {
    outline: $govuk-focus-width solid $govuk-focus-colour;
    outline-offset: 0;
    box-shadow: inset 0 0 0 $govuk-border-width-form-element;
  }
}
