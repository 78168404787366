$search-map__map-overlay-close-button-size: 30px;
$ol__button-icon-size: 30px;
$ol__button-spacing: 5px;

.search-map {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: white;
}

.search-map__map {
  height: 100%;
}

.search-map__map-overlay {
  position: relative;
  background: white;
  z-index: 1;
  flex-shrink: 0;
  padding: govuk-spacing(4);
  padding-right: govuk-spacing(4) + $search-map__map-overlay-close-button-size;
  min-height: 25vh;

  @include mq($from: tablet) {
    min-width: 470px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-height: 60px;
    border-radius: 3px;
    box-shadow: rgba(9, 30, 66, 0.15) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.04) 0px 0px 0px 1px;
  }
}

.search-map__map-overlay-close-button {
  display: block;
  position: absolute;
  top: 14px;
  right: govuk-spacing(2);
  appearance: none;
  background: none;
  border: none;
  line-height: 0;
  padding: 0;
  margin: 0;
  font-size: $search-map__map-overlay-close-button-size + 10px;
  width: $search-map__map-overlay-close-button-size;
  height: $search-map__map-overlay-close-button-size;
  flex-shrink: 0;

  &:focus {
    @include govuk-focused-text;
  }
}

.search-map__map-overlay-close-button-icon {
  display: block;
  width: $search-map__map-overlay-close-button-size;
  height: $search-map__map-overlay-close-button-size;
}

.search-map__spinner {
  display: block;
  color: #222;
  width: 19px;
  height: 19px;
}

.search-map__map-overlay-error {
  display: flex;
  align-items: flex-start;
}

.search-map__map-overlay-no-results,
.search-map__map-overlay-multiple-results,
.search-map__map-overlay-single-result {
  width: 470px;
  display: flex;
  align-items: flex-start;
}

.search-map__map-overlay-results-count {
  display: flex;
  justify-content: space-between;
}

.search-map__map-overlay-marker-icon {
  width: 30px;
  height: 40px;
  flex-shrink: 0;
  margin-right: govuk-spacing(2);
}

.search-map__search-wrapper {
  width: 100%;
  position: relative;
}

.search-map__search-box {
  position: absolute;
  top: govuk-spacing(3);
  left: 0;
  width: calc(90% - #{$ol__button-icon-size + 4 * $ol__button-spacing});
  z-index: 1;

  $icon-size: 44px;
  .rosgovuk-autocomplete__wrapper {
    display: block;
    position: relative;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' width='40' height='40'%3E%3Cpath d='M25.7 24.8L21.9 21c.7-1 1.1-2.2 1.1-3.5 0-3.6-2.9-6.5-6.5-6.5S10 13.9 10 17.5s2.9 6.5 6.5 6.5c1.6 0 3-.6 4.1-1.5l3.7 3.7 1.4-1.4zM12 17.5c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5z' fill='%23666666'%3E%3C/path%3E%3C/svg%3E")
      #fff no-repeat top left;
    background-size: $icon-size $icon-size;
    background-position: bottom left;
  }

  .rosgovuk-autocomplete__menu {
    width: 100%;
  }

  .rosgovuk-autocomplete__hint,
  .rosgovuk-autocomplete__input {
    background: transparent;
    height: govuk-spacing(7);
    padding-left: $icon-size - govuk-spacing(1);
  }
}

.open-names-search__link-icon,
.open-names-search__link-name {
  vertical-align: middle;
  display: inline-block;

  .rosgovuk-autocomplete__option--focused &,
  .rosgovuk-autocomplete__option:hover & {
    color: white;
  }
}

.open-names-search__link-icon {
  width: 24px;
  height: 24px;
  color: $govuk-link-colour;
  margin-right: govuk-spacing(1);
}
