.js-test-pending {
  display: flex;

  body:not(.js-requests-test-pending) & {
    display: none;
  }
}

.js-test-pending__spinner {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  color: #000;
  margin-right: govuk-spacing(4);
}

.js-test-pending__message {
  font-size: 16px;
  opacity: 0;
  height: 0;

  animation: fade-in 0.25s ease 20s 1 normal forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    height: auto;
  }

  100% {
    opacity: 1;
    height: auto;
  }
}
