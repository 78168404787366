$basket__delete-button-min-width: 47px;

.basket {
  border: solid 1px $govuk-border-colour;
  border-width: 1px 0;
  margin-bottom: govuk-spacing(4);
}

.basket__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.basket__list-item {
  padding: govuk-spacing(4) 0;

  &:not(:last-of-type) {
    border-bottom: solid 1px $govuk-border-colour;
  }

  @include mq($from: desktop) {
    display: flex;
  }
}

.basket__list-item-details {
  flex-grow: 1;
}

.basket__list-item-price,
.basket__list-item-delete-link {
  flex-shrink: 0;

  @include mq($from: desktop) {
    margin-left: govuk-spacing(4);
  }
}

.basket__list-item-delete-link {
  min-width: $basket__delete-button-min-width;

  @include mq($from: desktop) {
    margin-left: govuk-spacing(6);
  }
}

.basket__summary {
  display: flex;
  justify-content: space-between;
  padding: govuk-spacing(4) 0;
  border-top: solid 3px govuk-colour('dark-grey');
}

.basket__summary-price {
  flex-shrink: 0;
  margin-left: govuk-spacing(4);

  @include mq($from: desktop) {
    margin-right: $basket__delete-button-min-width + govuk-spacing(6);
  }
}
