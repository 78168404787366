.address-autocomplete {
  .autocomplete__input {
    height: govuk-spacing(7);

    @include mq($from: tablet) {
      width: 66.66%;
    }
  }

  .autocomplete__menu {
    @include mq($from: tablet) {
      width: 66.66%;
    }
  }
}
