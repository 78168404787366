.accepted-cards {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  float: none;
  overflow: hidden;
}

.accepted-cards__card {
  display: block;
  width: 36px;
  height: 24px;
  float: left;
  background-image: url(/images/accepted-cards-sprite.png);
  background-size: 900% 200%;

  &:not(:first-child) {
    margin-left: govuk-spacing(1);
  }
}

.accepted-cards__card--visa {
  background-position: 0% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--visa,
.accepted-cards__card--visa.is-selected {
  background-position: 0% 0%;
}

.accepted-cards__card--mastercard {
  background-position: 12.5% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--mastercard,
.accepted-cards__card--mastercard.is-selected {
  background-position: 12.5% 0%;
}

.accepted-cards__card--amex {
  background-position: 25% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--amex,
.accepted-cards__card--amex.is-selected {
  background-position: 25% 0%;
}

.accepted-cards__card--jcb {
  background-position: 37.5% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--jcb,
.accepted-cards__card--jcb.is-selected {
  background-position: 37.5% 0%;
}

.accepted-cards__card--diners {
  background-position: 50% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--diners,
.accepted-cards__card--diners.is-selected {
  background-position: 50% 0%;
}

.accepted-cards__card--discover {
  background-position: 62.5% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--discover,
.accepted-cards__card--discover.is-selected {
  background-position: 62.5% 0%;
}

.accepted-cards__card--maestro {
  background-position: 75% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--maestro,
.accepted-cards__card--maestro.is-selected {
  background-position: 75% 0%;
}

.accepted-cards__card--visa-electron {
  background-position: 87.5% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--visa-electron,
.accepted-cards__card--visa-electron.is-selected {
  background-position: 87.5% 0%;
}

.accepted-cards__card--unionpay {
  background-position: 100% 100%;
}

.accepted-cards.field-is-empty .accepted-cards__card--unionpay,
.accepted-cards__card--unionpay.is-selected {
  background-position: 100% 0%;
}
