.ol-drawing-control {
  position: absolute;
  right: 5px;
  background-color: white;
  box-sizing: border-box;

  .ol-drawing-control__freehand_button {
    z-index: 2;
    background-image: url('/images/pencil-alt-dark.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    background-color: white;

    &:focus,
    &:hover {
      background-image: url('/images/pencil-alt-light.svg');
      background-color: #005ea5;
    }

    &.active {
      background-color: yellow !important;

      &:focus,
      &:hover {
        background-image: url('/images/pencil-alt-dark.svg');
      }
    }
  }

  .ol-drawing-control__polygon_button {
    z-index: 2;
    background-image: url('/images/draw-polygon-dark.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    background-color: white;

    &:focus,
    &:hover {
      background-image: url('/images/draw-polygon-light.svg');
      background-color: #005ea5;
    }

    &.active {
      background-color: yellow !important;

      &:focus,
      &:hover {
        background-image: url('/images/draw-polygon-dark.svg');
      }
    }
  }
}

.ol-drawing-control__inline-toolbar {
  background: white;
  border: 1px solid #ddd;
  display: flex;

  .button {
    border-width: 0;
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: center;
    display: inline-block;
    height: 45px;
    padding: 0;
    width: 45px;

    &:hover {
      background-color: #005ea5;
    }

    &.edit {
      background-image: url('/images/draw-polygon-dark.svg');

      &:hover {
        background-image: url('/images/draw-polygon-light.svg');
      }
    }

    &.delete {
      background-image: url('/images/trash-alt-dark.svg');

      &:hover {
        background-image: url('/images/trash-alt-light.svg');
      }
    }
  }
}
