@import 'ros-frontend/ros/components/header/header';

$header__bubble-size: 20px;
$header__bubble-color: #d10019;
$header__bubble-font-size: 14px;

.govuk-header__button-icon-bubble {
  $padding-y: ($header__bubble-size - $header__bubble-font-size) / 2;

  position: absolute;
  right: 0;
  top: 3px;
  background: $header__bubble-color;
  border-radius: $header__bubble-size / 2;
  min-width: $header__bubble-size;
  padding: $padding-y govuk-spacing(1);
  font-size: $header__bubble-font-size;
  font-weight: 700;
  font-variant-numeric: tabular-nums;
  line-height: 1;
  text-align: center;
  color: #fff;
  transform: translateX(50%);
  pointer-events: none;
}

.govuk-header__logo .govuk-header__holly-icon {
  position: absolute;
  width: 47px;
  left: -38px;
  top: 3px;
  transform: rotate(-75deg);
  display: none;

  @include mq($from: tablet) {
    display: block;
  }
}

.govuk-header__button--basket {
  margin-bottom: govuk-spacing(2) * -1;
}
