@media print {
  .ol-attribution {
    display: inline;
    white-space: nowrap;
  }
}

.ol-attribution:not(.ol-collapsed) {
  background-color: white;
  font-family: $govuk-font-family;
  font-size: 12px;

  @include mq($from: tablet) {
    font-size: 14px;
  }

  @include mq($from: desktop) {
    font-size: 16px;
  }
}
