$persistent-search__bg-color: #005ea5;
$persistent-search__color: #fff;
$persistent-search__padding: 8px;

.persistent-search {
  background: $persistent-search__bg-color;

  $mobile-global-margin: govuk-spacing(3);
  $tablet-global-margin: govuk-spacing(6);
  padding: $persistent-search__padding $mobile-global-margin;
  padding-bottom: 0;
  margin: 0 (-$mobile-global-margin);

  @include mq($from: tablet) {
    margin: 0 (-$tablet-global-margin);
    padding: $persistent-search__padding $tablet-global-margin;
    padding-bottom: 0;
  }

  @include mq($from: desktop) {
    padding: $persistent-search__padding;
    padding-bottom: 0;
    margin: 0;
  }
}

.persistent-search__link {
  &,
  &:link,
  &:visited {
    display: inline-block;
    background: $persistent-search__bg-color;
    color: $persistent-search__color;
    font-family: $govuk-font-family;
    margin-bottom: $persistent-search__padding;
  }

  &:focus {
    @include govuk-focused-text;
    color: black;
  }

  &:not(:last-of-type) {
    margin-right: govuk-spacing(4);
  }
}

.persistent-search__icon {
  vertical-align: middle;
  margin-right: govuk-spacing(1);
}
