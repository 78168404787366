.basket-warning__buttons-container {
  @include mq($from: tablet) {
    display: flex;
    align-items: center;
  }
}

.basket-warning__continue-button-container {
  margin-bottom: govuk-spacing(4);
  @include mq($from: tablet) {
    margin-bottom: 0px;
    margin-right: govuk-spacing(4);
  }
}
