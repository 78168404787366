.buorg.buorg {
  position: relative;
  background-color: #fff700;
  font-family: $govuk-font-family;

  #buorgig,
  #buorgul,
  #buorgpermanent {
    color: #000;
    text-decoration: underline;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    font-weight: normal;
    background: transparent;
    margin: 0;
    display: inline;
  }

  .buorg-pad {
    line-height: 1.4;
  }
}
