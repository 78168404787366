.open-names-search {
  background: white;
  padding: govuk-spacing(3);
  max-width: 520px;
  box-shadow: rgba(9, 30, 66, 0.15) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.04) 0px 0px 0px 1px;
  border-radius: 3px;
}

.open-names-search__option-name,
.open-names-search__option-locality {
  display: block;
}

.open-names-search__option-locality {
  font-size: 0.8em;
}
