.payment-processing__text {
  text-align: center;
  margin-top: govuk-spacing(9);
  margin-bottom: govuk-spacing(9);
}

.payment-processing__scotlis-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-bottom: govuk-spacing(9);

  @include mq($from: desktop) {
    width: 50%;
  }
}

.payment-processing__powered-by-stripe-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 250px;
  margin-bottom: govuk-spacing(9);

  @include mq($from: desktop) {
    width: 50%;
  }
}
