.latest-change-bar {
  display: flex;
  align-items: center;
  margin-bottom: govuk-spacing(4);
}

.latest-change-bar__badge-container {
  margin-right: govuk-spacing(2);
  flex-shrink: 0;

  @include mq($from: tablet) {
    margin-right: govuk-spacing(4);
  }
}
