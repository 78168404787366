$ol__button-size: 50px;
$ol__button-icon-size: 30px;
$ol__button-spacing: 5px;

.ol-control {
  background: none;
  padding: 0;
  border-radius: 0;
  z-index: 1;

  button {
    background-color: #fff;
    color: #000;
    border-radius: 0;
    width: $ol__button-size;
    height: $ol__button-size;
    font-size: 50px;
    font-weight: 300;
    margin: 0;
    border-radius: 3px;
    box-shadow: rgba(9, 30, 66, 0.15) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.04) 0px 0px 0px 1px;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:focus,
    &:hover {
      outline: none;
      background-color: #005ea5 !important;
    }

    .ol-touch & {
      font-size: 50px;
    }
  }

  .ol-close-button {
    background-color: #d0011b;
    color: #fff;

    &:focus,
    &:hover {
      background-color: darken(#d0011b, 10%) !important;
    }
  }
}

.ol-close {
  top: $ol__button-spacing;
  right: $ol__button-spacing;
}

.ol-zoom {
  right: $ol__button-spacing;
  top: ($ol__button-size) * 2 + $ol__button-spacing;
  left: auto;

  .ol-zoom-in {
    margin-bottom: $ol__button-spacing;
  }
}

.ol-geolocation {
  right: $ol__button-spacing;
  top: ($ol__button-size * 5) + ($ol__button-spacing * 2);
}

.ol-fit-to-layer-extent {
  top: $ol__button-spacing;
  right: $ol__button-spacing;

  .search-map & {
    top: ($ol__button-size * 6) + ($ol__button-spacing * 3);
  }
}

.ol-toggle-aerial-layers {
  top: auto;
  right: auto;
  left: 10px;
  bottom: 50px;

  button {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: solid 2px #fff;
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.5),
      inset 0 -50px 30px -20px rgba(0, 0, 0, 0.8);

    @include mq($from: tablet) {
      width: 80px;
      height: 80px;
    }

    @include mq($from: desktop) {
      width: 120px;
      height: 120px;
    }
  }
}

.ol-toggle-aerial-layers-button-content {
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.ol-full-screen {
  top: $ol__button-spacing;
  right: $ol__button-spacing;
}

.ol-drawing-control {
  top: ($ol__button-size * 5) + ($ol__button-spacing * 2);
}

.ol-close-button,
.ol-full-screen-false,
.ol-full-screen-true,
.ol-zoom-in,
.ol-zoom-out,
.ol-geolocation-button,
.ol-fit-to-layer-extent-button {
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: 50%;
}

.ol-close-button {
  background-image: url('/images/cross-icon-light.svg');
}

.ol-zoom-in {
  @include preloadImage('/images/plus-icon-light.svg');
  background-image: url('/images/plus-icon-dark.svg');

  &:focus,
  &:hover {
    background-image: url('/images/plus-icon-light.svg');
  }
}

.ol-zoom-out {
  @include preloadImage('/images/minus-icon-light.svg');
  background-image: url('/images/minus-icon-dark.svg');

  &:focus,
  &:hover {
    background-image: url('/images/minus-icon-light.svg');
  }
}

.ol-geolocation-button {
  @include preloadImage('/images/geolocation-icon-light.svg');
  background-image: url('/images/geolocation-icon-dark.svg');
  background-size: 60%;

  &:focus,
  &:hover {
    background-image: url('/images/geolocation-icon-light.svg');
  }
}

.ol-fit-to-layer-extent-button {
  @include preloadImage('/images/fit-to-extent-icon-light.svg');
  background-image: url('/images/fit-to-extent-icon-dark.svg');
  background-size: 70%;

  &:focus,
  &:hover {
    background-image: url('/images/fit-to-extent-icon-light.svg');
  }
}

.ol-toggle-aerial-layers-button {
  @include preloadImage('/images/graphical-map-tile.png');
  background-image: url('/images/aerial-map-tile.png');
  background-repeat: no-repeat;
  background-size: cover;

  &.is-toggled {
    background-image: url('/images/graphical-map-tile.png');
  }

  &:focus {
    border-color: #ffdd00;
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.5),
      inset 0 -50px 30px -20px rgba(0, 0, 0, 0.8), 0 0px 0px 2px rgb(0, 0, 0);
  }

  &:hover {
    border-color: #ffdd00;
  }
}

.ol-full-screen-true,
.ol-full-screen-false {
  background-size: 80%;
}

.ol-full-screen-false {
  @include preloadImage('/images/resize-full-light.svg');
  background-image: url('/images/resize-full-dark.svg');

  &:focus,
  &:hover {
    background-image: url('/images/resize-full-light.svg');
  }
}

.ol-full-screen-true {
  @include preloadImage('/images/resize-small-light.svg');
  background-size: 80%;
  width: 100%;
  height: 100%;
  background-image: url('/images/resize-small-dark.svg');

  &:focus,
  &:hover {
    background-image: url('/images/resize-small-light.svg');
  }
}

.ol-print-map {
  right: 5px;
  top: 265px;
  left: auto;

  .ol-print-map__button {
    @include preloadImage('/images/print-icon-light.svg');
    background-image: url('/images/print-icon-dark.svg');
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: 50%;

    &:focus,
    &:hover {
      background-image: url('/images/print-icon-light.svg');
    }
  }
}
