@import 'ol/ol';

.map-container {
  width: 100%;
  height: 600px;
}

.map-container--small {
  height: 250px;
}
